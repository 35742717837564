<template>
  <div class="page-wrapper">
    <h1>Gestion des utilisateur</h1>

    <h2>Liste des utilisateur</h2>
    <form @submit.prevent>
      <input v-model="search" type="text" placeholder="Rechercher un utilisateur" @keyup="filterUsersList" />
    </form>

    <v-table
      v-if="filteredUsersList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="filteredUsersList"
    >
      <thead slot="head">
        <v-th sort-key="firstname">Nom de l'utilisateur</v-th>
        <v-th sort-key="employeeID">Code vendeur</v-th>
        <v-th sort-key="location">Succursale</v-th>
        <v-th sort-key="email">Courriel</v-th>
        <v-th sort-key="nbSignIn">Nombre de connexion</v-th>
        <v-th sort-key="nbSignInUpdatedAt">Dernière connexion</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.firstname }} {{ row.lastname }}</td>
          <td>{{ row.employeeID }}</td>
          <td>{{ row.location }}</td>
          <td>{{ row.email }}</td>
          <td>{{ row.nbSignIn }}</td>
          <td>{{ row.nbSignInUpdatedAt }}</td>
          <td>
            <button @click.prevent="editUser($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteUser($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <v-table
      v-else-if="usersList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="usersList"
    >
      <thead slot="head">
        <v-th sort-key="firstname">Nom de l'utilisateur</v-th>
        <v-th sort-key="employeeID">Code vendeur</v-th>
        <v-th sort-key="location">Succursale</v-th>
        <v-th sort-key="email">Courriel</v-th>
        <v-th sort-key="nbSignIn">Nombre de connexion</v-th>
        <v-th sort-key="nbSignInUpdatedAt">Dernière connexion</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.firstname }} {{ row.lastname }}</td>
          <td>{{ row.employeeID }}</td>
          <td>{{ row.location }}</td>
          <td>{{ row.email }}</td>
          <td>{{ row.nbSignIn }}</td>
          <td>{{ row.nbSignInUpdatedAt }}</td>
          <td>
            <button @click.prevent="editUser($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteUser($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <h2>Modification</h2>
    <!-- Auth UI -->
    <form @submit="saveUser($event)">
      <div>
        <label for="firstname">Prénom</label>
        <input id="firstname" v-model="selectedUser.firstname" type="text" required />
      </div>

      <div>
        <label for="lastname">Nom</label>
        <input id="lastname" v-model="selectedUser.lastname" type="text" required />
      </div>

      <div>
        <label for="employeeID">Code vendeur</label>
        <input id="employeeID" v-model="selectedUser.employeeID" type="text" required />
      </div>

      <div>
        <label for="location">Succursale</label>
        <select v-model="selectedUser.location" name="location" required>
          <option v-for="(location, index) in locationsList" :key="index" :value="location.name">
            {{ location.name }}
          </option>
        </select>
      </div>

      <div>
        <label for="phone">Téléphone</label>
        <div class="phone-input">
          <span>+1</span>
          <the-mask
            id="phone"
            v-model="selectedUser.phone"
            mask="###-###-####"
            type="tel"
            :masked="true"
            required
            placeholder="xxx-xxx-xxxx"
          ></the-mask>
        </div>
      </div>

      <div>
        <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
          <div class="bt-text">
            <p>Modifer</p>
          </div>
          <p class="loader">
            &nbsp;
            <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
              <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </svg>
            &nbsp;
          </p>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UsersDB from '@/firebase/users-db'
import LocationsDB from '@/firebase/locations-db'
import { TheMask } from 'vue-the-mask'

export default {
  components: { TheMask },
  data: () => ({
    isLoading: false,
    selectedUser: {},
    usersList: null,
    locationsList: null,
    filteredUsersList: null,
    search: ''
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  mounted() {
    this.getUsersList()
    this.getLocationsList()
  },
  methods: {
    async saveUser(e) {
      e.preventDefault()

      this.isLoading = true

      const user = { ...this.selectedUser }
      const usersDB = new UsersDB()

      await usersDB.update(user)

      this.getUsersList()

      this.selectedUser = {}
      this.isLoading = false
    },
    async editUser(e, user) {
      this.isLoading = true
      const usersDB = new UsersDB()
      this.selectedUser = await usersDB.read(user.id)
      this.isLoading = false
    },
    async deleteUser(e, userId) {
      this.isLoading = true

      const userChoice = await this.$swal({
        title: 'Supprimer',
        text: `Confirmer la suppression.`,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      })

      if (!userChoice.isConfirmed) {
        this.isLoading = false
        return
      }

      const usersDB = new UsersDB()
      await usersDB.delete(userId)
      this.getUsersList()
      this.isLoading = false
    },
    async getUsersList() {
      this.isLoading = true
      const usersDB = new UsersDB()
      this.usersList = await usersDB.readAll()
      this.isLoading = false
    },
    async getLocationsList() {
      this.isLoading = true
      const locationsDB = new LocationsDB()
      this.locationsList = await locationsDB.readAll()
      this.isLoading = false
    },
    filterUsersList() {
      if (!this.usersList) {
        return
      }

      if (this.search && this.search.length >= 3) {
        this.filteredUsersList = this.usersList.filter(i => {
          return i.firstname.includes(this.search) || i.lastname.includes(this.search) || i.email.includes(this.search)
        })
      } else {
        this.filteredUsersList = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
